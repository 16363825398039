import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
import { SocialIcons } from "../components";
import { MobileMenu, Navigation } from ".";

export const Header = () => {
  return (
    <header>
      <Link to="/">
        <img
          src={logo}
          className="logo"
          alt="Brady and the Bunch - Glass Pros"
        />
      </Link>
      <div className="nav-social-wrapper">
        <SocialIcons />
        <Navigation />
      </div>
      <MobileMenu />
    </header>
  );
};
