import {
  Contact,
  Residential,
  Commercial,
  Process,
  Testimonials,
  Services,
  SmartTint,
  Welcome,
} from "../pages";

export const Home = () => {
  return (
    <main>
      <Welcome />
      <Services />
      <Residential />
      <Commercial />
      <Process />
      <Testimonials />
      <SmartTint />
      <Contact />
    </main>
  );
};
