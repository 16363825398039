import { HashScroll } from "react-hash-scroll";
import "../styles/Process.scss";
import { Steps } from "../components";

export const Process = () => {
  return (
    <HashScroll hash="process">
      <article id="process" className="row">
        <div className="content row">
          <div className="col">
            <h1>Talk about simplicity</h1>
            <p>
              Obtaining a quote is a straightforward, hassle-free process. Our
              team of experts is just a phone call or email away, ready to
              provide you with a personalized quote, ensuring a smooth and
              efficient experience.
            </p>
          </div>
          <Steps classes="col" />
        </div>
      </article>
    </HashScroll>
  );
};
