import { HashScroll } from "react-hash-scroll";
import { Link } from "react-router-dom";
import PoolPic from "../assets/portfolio/railings-7.jpg";
import "../styles/Residential.scss";

export const Residential = () => {
  return (
    <HashScroll hash="residential">
      <article id="residential" className="row">
        <div className="col">
          <img src={PoolPic} alt="" />
        </div>
        <div className="content col">
          <h5 className="trans">
            Bathroom - Kitchen - Stairs - Balcony - Pool - Decor
          </h5>
          <h1>Residential</h1>
          <p>
            Transform your home with our premium residential glass services. Our
            team is dedicated to enhancing your living space with the beauty and
            functionality of high-quality glass. Whether you're looking to
            replace or upgrade your windows and doors with energy-efficient
            options for your newly built house or want to explore custom glass
            solutions, we have the expertise to bring your vision to life. Our
            commitment to safety and security is reflected in our durable safety
            glass options, providing peace of mind for you and your family. We
            take pride in offering prompt repairs and replacements, ensuring
            your home remains a safe and comfortable haven. Discover the endless
            possibilities that residential glass can offer and experience the
            difference that quality and craftsmanship make in your living
            environment. Let your home shine through our residential glass
            services.
          </p>
          <Link to="#contact" className="btn">
            Contact Us
          </Link>
        </div>
      </article>
    </HashScroll>
  );
};
