import { HashScroll } from "react-hash-scroll";
import "../styles/Services.scss";
import { Email, Package } from "../components";
import { Link } from "react-router-dom";

export const Services = () => {
  return (
    <HashScroll hash="services">
      <article id="services">
        <h5 className="trans">What We Offer</h5>
        <h1>Elevate your space with our services</h1>
        <p>
          Enhance your home or business with our expert glass services, tailored
          to meet your needs in North Georgia.
        </p>
        <div className="row">
          <Package />
          <Email />
        </div>
        <h2>
          Need Help Immediately?{" "}
          <Link to="#contact">
            <strong>Contact Us Now!</strong>
          </Link>
        </h2>
      </article>
    </HashScroll>
  );
};
