import { useState } from "react";
import { createUUID } from "../helpers/uid";
import "../styles/Toggle.scss";

export const Toggle = ({ handleToggle, options }) => {
  const [activeTab, setActiveTab] = useState(options[0]);

  const handleClick = (value) => {
    handleToggle(value);
    setActiveTab(value);
  };

  return (
    <div className="toggle">
      {options.map((option) => (
        <span
          className={activeTab === option ? "active" : ""}
          key={createUUID()}
          onClick={() => handleClick(option)}
        >
          {option}
        </span>
      ))}
    </div>
  );
};
