import { Navigation, PhoneBtn } from ".";
import { Link } from "react-router-dom";
import { SocialIcons } from "../components";
import logo from "../assets/logo.svg";

const year = new Date().getFullYear();

export const Footer = () => {
  return (
    <footer>
      <img src={logo} className="logo" alt="Brady and the Bunch - Glass Pros" />
      <Navigation />
      <PhoneBtn />
      <SocialIcons />
      <p className="copyright">
        &copy; {year} Brady and the Bunch. All Rights Reserved.{" "}
        <Link to="/privacy-policy">Privacy Policy</Link> | Built by{" "}
        <a href="https://craftandcode.io" target="_blank" rel="noreferrer">
          Craft & Code, LLC
        </a>
        .
      </p>
    </footer>
  );
};
