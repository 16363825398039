import { HashScroll } from "react-hash-scroll";
import { Link } from "react-router-dom";
import "../styles/Welcome.scss";

export const Welcome = () => {
  return (
    <HashScroll hash="welcome">
      <article id="welcome" className="row">
        <div className="hero-content">
          <h1>We are Atlanta's premier glass experts</h1>
          <h3>Transforming Spaces with Precision Glass Solutions</h3>
          <div className="actions">
            <Link to="#contact" className="btn">
              Contact Us
            </Link>
            <Link to="#services" className="btn white">
              Learn More
            </Link>
          </div>
        </div>
      </article>
    </HashScroll>
  );
};
