import { HashScroll } from "react-hash-scroll";
import { Link } from "react-router-dom";
import Office from "../assets/portfolio/office-2.jpg";
import "../styles/Commercial.scss";

export const Commercial = () => {
  return (
    <HashScroll hash="commercial">
      <article id="commercial" className="row">
        <div className="col">
          <h5 className="trans">Conference Rooms - Doors - Lobby</h5>
          <h1>Commercial</h1>
          <p>
            Elevate your business with our top-tier commercial glass services.
            We understand that every detail matters, and our dedicated team is
            committed to providing you with the highest quality glass solutions.
            Whether you require stunning storefront glass that welcomes
            customers with a modern, inviting ambiance or need sturdy curtain
            walls for structural integrity, we've got you covered. Our expertise
            extends to energy-efficient glass installations, enhancing your
            space while reducing your environmental footprint. When it comes to
            safety, our tempered and laminated safety glass ensures peace of
            mind for you and your customers. Our experienced professionals are
            ready to deliver expert craftsmanship and prompt repairs when
            needed, keeping your business running smoothly. Explore the
            possibilities with our commercial glass services, where precision
            meets aesthetics, and let your vision shine through the glass.
          </p>
          <Link to="#contact" className="btn">
            Get Started
          </Link>
        </div>
        <div className="col">
          <img src={Office} alt="" />
        </div>
      </article>
    </HashScroll>
  );
};
