export const PaymentFailure = () => {
  return (
    <main>
      <article>
        <div className="content">
          <h1>Payment Failure</h1>
          <p>There was a problem capturing your payment.</p>
        </div>
      </article>
    </main>
  );
};
