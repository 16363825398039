import { useState } from "react";
import { HashScroll } from "react-hash-scroll";
import { Toggle } from "../components";
import { createUUID } from "../helpers/uid";
import "../styles/Testimonials.scss";
import { portfolioOptions, Testimonies } from "./portfolio";

export const BasicTestimony = ({ details }) => {
  return (
    <div className="testimony">
      {details.image && <img src={details.image} alt="testimonial" />}
    </div>
  );
};

export const Testiimony = ({ details }) => {
  return (
    <div className="testimony">
      {details.image && (
        <div className="photo">
          <img src={details.image} alt="testimonial" />
        </div>
      )}
      <p>{details.content}</p>
      <strong>{details.name}</strong>
      {details.company && <p className="company">{details.company}</p>}
    </div>
  );
};

export const Testimonials = () => {
  const [filterBy, setFilterBy] = useState(portfolioOptions[0]);

  const handleToggle = (value) => {
    setFilterBy(value);
  };

  return (
    <HashScroll hash="testimonials">
      <article id="testimonials" className="row">
        <div className="content">
          <h1>Showcasing Our Work</h1>
          <p>
            Explore our portfolio to witness a stunning collection of our glass
            masterpieces, each a testament to our commitment to quality,
            craftsmanship, and innovative design. We specialize in frameless
            showers & mirrors, railing glass & sneeze guards, storefronts &
            curtain walls & SSG, wine cellars & partitions, custom glass & edge
            work, 3D point scan templating service
          </p>
          <Toggle options={portfolioOptions} handleToggle={handleToggle} />
          <div className="testimony-container">
            {Testimonies.map(
              (testimony) =>
                filterBy === testimony.category && (
                  <BasicTestimony key={createUUID()} details={testimony} />
                )
            )}
          </div>
        </div>
      </article>
    </HashScroll>
  );
};
