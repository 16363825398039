export const PaymentSuccess = () => {
  return (
    <main>
      <article>
        <div className="content">
          <h1>Payment Success</h1>
          <p>Thank you for your payment</p>
        </div>
      </article>
    </main>
  );
};
