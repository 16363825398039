import Cellar1 from "../assets/portfolio/cellar-1.jpg";
import Cellar2 from "../assets/portfolio/cellar-2.jpg";
import Custom1 from "../assets/portfolio/custom-1.jpg";
import Custom2 from "../assets/portfolio/custom-2.jpg";
import Custom3 from "../assets/portfolio/custom-3.jpg";
import Custom4 from "../assets/portfolio/custom-4.jpg";
import Office1 from "../assets/portfolio/office-1.jpg";
import Office2 from "../assets/portfolio/office-2.jpg";
import Mirror1 from "../assets/portfolio/mirror-1.jpg";
import Mirror2 from "../assets/portfolio/mirror-2.jpg";
import Mirror3 from "../assets/portfolio/mirror-3.jpg";
import Mirror4 from "../assets/portfolio/mirror-4.jpg";
import Mirror5 from "../assets/portfolio/mirror-5.jpg";
import Bathroom1 from "../assets/portfolio/bathroom-1.jpg";
import Bathroom2 from "../assets/portfolio/bathroom-3.jpg";
import Bathroom3 from "../assets/portfolio/bathroom-7.jpg";
import Bathroom4 from "../assets/portfolio/bathroom-6.jpg";
import Bathroom5 from "../assets/portfolio/bathroom-5.jpg";
import Bathroom6 from "../assets/portfolio/bathroom-4.jpg";
import Bathroom7 from "../assets/portfolio/bathroom-2.jpg";
import Railings1 from "../assets/portfolio/railings-1.jpg";
import Railings2 from "../assets/portfolio/railings-2.jpg";
import Railings3 from "../assets/portfolio/railings-3.jpg";
import Railings4 from "../assets/portfolio/railings-5.jpg";
import Railings5 from "../assets/portfolio/railings-4.jpg";
import Railings6 from "../assets/portfolio/railings-6.jpg";
import Railings7 from "../assets/portfolio/stairs-2.jpg";
import Railings8 from "../assets/portfolio/sneeze-1.jpg";
import Railings9 from "../assets/portfolio/stairs-1.jpg";
import Railings10 from "../assets/portfolio/railings-7.jpg";
import Storefronts1 from "../assets/portfolio/storefronts-1.jpg";
import Storefronts2 from "../assets/portfolio/storefronts-2.jpg";
import Templating1 from "../assets/portfolio/templating-1.jpg";
import Templating2 from "../assets/portfolio/templating-2.jpg";
import Templating3 from "../assets/portfolio/templating-3.jpg";

// FRAMELESS    frameless showers & mirrors
// RAILING      railing glass & sneeze guards
// STOREFRONTS  storefronts & curtain walls & SSG
// PARTITIONS   wine cellars & partitions
// CUSTOM       custom glass & edge work
// TEMPLATING   3D point scan templating service

export const portfolioOptions = [
  "frameless",
  "railings",
  "storefronts",
  "partitions",
  "custom",
  "templating",
];

export const Testimonies = [
  {
    category: "railings",
    image: Railings1,
  },
  {
    category: "railings",
    image: Railings2,
  },
  {
    category: "railings",
    image: Railings3,
  },
  {
    category: "railings",
    image: Railings4,
  },
  {
    category: "railings",
    image: Railings5,
  },
  {
    category: "railings",
    image: Railings6,
  },
  {
    category: "railings",
    image: Railings7,
  },
  {
    category: "railings",
    image: Railings8,
  },
  {
    category: "railings",
    image: Railings9,
  },
  {
    category: "railings",
    image: Railings10,
  },
  {
    category: "frameless",
    image: Bathroom1,
  },
  {
    category: "frameless",
    image: Mirror5,
  },
  {
    category: "frameless",
    image: Bathroom2,
  },
  {
    category: "frameless",
    image: Mirror3,
  },
  {
    category: "frameless",
    image: Bathroom3,
  },
  {
    category: "frameless",
    image: Bathroom4,
  },
  {
    category: "frameless",
    image: Mirror1,
  },
  {
    category: "frameless",
    image: Mirror2,
  },
  {
    category: "frameless",
    image: Bathroom5,
  },
  {
    category: "frameless",
    image: Bathroom6,
  },
  {
    category: "frameless",
    image: Bathroom7,
  },
  {
    category: "frameless",
    image: Mirror4,
  },
  {
    category: "partitions",
    image: Cellar1,
  },
  {
    category: "partitions",
    image: Cellar2,
  },
  {
    category: "partitions",
    image: Office1,
  },
  {
    category: "partitions",
    image: Office2,
  },
  {
    category: "custom",
    image: Custom1,
  },
  {
    category: "custom",
    image: Custom2,
  },
  {
    category: "custom",
    image: Custom3,
  },
  {
    category: "custom",
    image: Custom4,
  },
  {
    category: "storefronts",
    image: Storefronts1,
  },
  {
    category: "storefronts",
    image: Storefronts2,
  },
  {
    category: "templating",
    image: Templating1,
  },
  {
    category: "templating",
    image: Templating2,
  },
  {
    category: "templating",
    image: Templating3,
  },
];
