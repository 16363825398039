import { createUUID } from "../helpers/uid";
import "../styles/Steps.scss";

export const StepContent = [
  {
    description:
      "Fill out a Free Consultation form and tell us about your project. A member of our team will help you find the right solution to meet your goals.",
  },
  {
    description:
      "Custom glass is made to order. We will schedule a time to come out and measure your space. We will also discuss your design options and answer any questions you may have.",
  },
  {
    description:
      "Once your glass is ready, we will schedule a time to install it.",
  },
];

export const Steps = ({ classes }) => {
  return (
    <article id="steps" className={classes}>
      <ul>
        {StepContent.map((step, index) => (
          <li key={createUUID()}>
            <h4>Step {index + 1}</h4>
            <img src={step.image} alt="" />
            <p>{step.description}</p>
          </li>
        ))}
      </ul>
    </article>
  );
};
