import discordIcon from "../assets/icon-discord.svg";
import facebookIcon from "../assets/icon-facebook.svg";
import instagramIcon from "../assets/icon-instagram.svg";
import linkedInIcon from "../assets/icon-linkedin.svg";
import telegramIcon from "../assets/icon-telegram.svg";
import twitterIcon from "../assets/icon-twitter.svg";
import twitchIcon from "../assets/icon-twitch.svg";

export const discord = "";
export const telegram = "";
export const twitter = "";
export const instagram = "https://www.instagram.com/bradyproservices";
export const twitch = "";
export const facebook = "https://www.facebook.com/BradyProServices";
export const linkedin = "https://www.linkedin.com/in/matthew-brady-06095843/";

export const DiscordLink = () => {
  return (
    <a className="btn" href={discord} rel="noreferrer" target="_blank">
      <span>Discord</span>
    </a>
  );
};

export const TelegramLink = () => {
  return (
    <a className="btn" href={telegram} rel="noreferrer" target="_blank">
      <span>Telegram</span>
    </a>
  );
};

export const SocialIcons = () => {
  return (
    <div className="social-links">
      {facebook !== "" && (
        <a
          href={facebook}
          rel="noreferrer"
          target="_blank"
          title="Find us on Facebook"
        >
          <img src={facebookIcon} alt="Facebook" />
        </a>
      )}
      {telegram !== "" && (
        <a
          href={telegram}
          rel="noreferrer"
          target="_blank"
          title="Join us on Telegram"
        >
          <img src={telegramIcon} alt="Telegram" />
        </a>
      )}
      {discord !== "" && (
        <a
          href={discord}
          rel="noreferrer"
          target="_blank"
          title="Join us on Discord"
        >
          <img src={discordIcon} alt="Discord" />
        </a>
      )}
      {linkedin !== "" && (
        <a
          href={linkedin}
          rel="noreferrer"
          target="_blank"
          title="Join us on LinkedIn"
        >
          <img src={linkedInIcon} alt="LinkedIn" />
        </a>
      )}
      {twitch !== "" && (
        <a
          href={twitch}
          rel="noreferrer"
          target="_blank"
          title="Join us on Twitch"
        >
          <img src={twitchIcon} alt="Twitch" />
        </a>
      )}
      {twitter !== "" && (
        <a
          href={twitter}
          rel="noreferrer"
          target="_blank"
          title="Follow us on Twitter"
        >
          <img src={twitterIcon} alt="Twitter" />
        </a>
      )}
      {instagram !== "" && (
        <a href={instagram} rel="noreferrer" target="_blank">
          <img src={instagramIcon} alt="Instagram" />
        </a>
      )}
    </div>
  );
};
