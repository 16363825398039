import { Link } from "react-router-dom";

export const PhoneBtn = () => {
  return (
    <a
      href="tel:678-218-9315"
      rel="noreferrer"
      target="_blank"
      className="btn phone-btn"
    >
      678-218-9315
    </a>
  );
};

export const Navigation = ({ onNavigate }) => {
  const handleClick = () => {
    if (onNavigate) {
      onNavigate();
    }
  };

  return (
    <nav>
      <ul>
        <li>
          <Link to="/" onClick={handleClick}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/#services" onClick={handleClick}>
            Services
          </Link>
        </li>
        <li>
          <Link to="/#process" onClick={handleClick}>
            Process
          </Link>
        </li>
        <li>
          <Link to="/#testimonials" onClick={handleClick}>
            Testimonials
          </Link>
        </li>
        <li>
          <Link to="/#contact" onClick={handleClick}>
            Contact
          </Link>
        </li>
        <li>
          <PhoneBtn />
        </li>
      </ul>
    </nav>
  );
};
