import { HashScroll } from "react-hash-scroll";
import { Email } from "../components";
import "../styles/Contact.scss";

export const Contact = () => {
  return (
    <HashScroll hash="contact">
      <article id="contact">
        <div className="content">
          <h1>Ready to work with us?</h1>
          <p>
            Get your estimate by filling out the form below and we will get in
            touch.
          </p>
          <Email />
        </div>
      </article>
    </HashScroll>
  );
};
