import { HashScroll } from "react-hash-scroll";
import SmartTintBlack from "../assets/SmartTintDealerBlack.png";
import "../styles/SmartTint.scss";

export const SmartTint = () => {
  return (
    <HashScroll hash="smart-tint">
      <article id="smart-tint">
        <div className="content">
          <h1>Smart Tint Authorized Dealer</h1>
          <p>
            We are Georgia's Dealer for Switchable Smart Film. Try our
            interactive tool that allows you to design and provide specifics
            like glass type and thickness as well as dimensions.
          </p>
          <a
            href="https://smarttintdealer.com/bradyproservices/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={SmartTintBlack} alt="Smart Tint Dealer" width="300" />
          </a>
        </div>
      </article>
    </HashScroll>
  );
};
