import { useState } from "react";
import { send } from "@emailjs/browser";
import "../styles/Email.scss";

// EmailJS service ID
const serviceId = "service_1k6cf2t";
const templateId = "template_c6mudu8";
const userId = "Rta3_8bsCHSC0J-RC";

const defaultFormState = {
  name: "",
  email: "",
  phone: "",
  message: "",
  sms: "yes",
};

export const Email = () => {
  const [toSend, setToSend] = useState(defaultFormState);
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");

  const handleThanks = () => {
    setToSend(defaultFormState);
    setSending(false);
    setMessage("Thanks for reaching out! We will get back to you soon.");
  };

  const handleError = () => {
    setSending(false);
    setMessage(
      "Something went wrong, please email us directly at info@bradyproglass.com."
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    send(serviceId, templateId, toSend, userId)
      .then((_response) => {
        handleThanks();
      })
      .catch((_err) => {
        console.error(_err);
        handleError();
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const handleCheckbox = (e) => {
    setToSend({ ...toSend, sms: e.target.checked ? "yes" : "no" });
  };

  return (
    <form id="email" onSubmit={onSubmit}>
      <h4>Leave a message</h4>
      {message && <h2>{message}</h2>}
      <input
        id="name"
        name="name"
        onChange={handleChange}
        placeholder="Name"
        required={true}
        type="text"
        value={toSend.name}
      />
      <input
        id="email"
        name="email"
        onChange={handleChange}
        placeholder="Email"
        required={true}
        type="email"
        value={toSend.email}
      />
      <input
        id="phone"
        name="phone"
        onChange={handleChange}
        placeholder="Phone"
        required={true}
        type="phone"
        value={toSend.phone}
      />
      <textarea
        id="message"
        name="message"
        onChange={handleChange}
        placeholder="Message"
        required={true}
        rows={5}
        value={toSend.message}
      />
      <div className="checkbox">
        <input
          type="checkbox"
          id="sms"
          name="sms"
          onChange={handleCheckbox}
          checked={toSend.sms}
        />
        <label htmlFor="sms">
          I agree to receive updates via SMS to the number provided above.
        </label>
      </div>
      <button type="submit" className="btn">
        {sending ? "Sending..." : "Contact Us"}
      </button>
    </form>
  );
};
