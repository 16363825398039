import { createUUID } from "../helpers/uid";
import "../styles/Packages.scss";

export const Packages = [
  {
    name: "Residential",
    price: "",
    description: "",
    features: [
      "Frameless Showers",
      "Railing Glass",
      "Office Partitions",
      "Large Sliding Doors",
      "Custom Glass Projects",
    ],
    link: "",
    linkText: "",
    highlight: false,
  },
  {
    name: "Commercial",
    price: "",
    description: "",
    features: [
      "Store Fronts",
      "Bi-Fold Doors",
      "Custom Mirrors",
      "Enclosed Porches",
      "Insulated Glass Replacement",
    ],
    link: "",
    linkText: "",
    highlight: false,
  },
];

export const Package = () => {
  return (
    <article id="packages" className="row">
      {Packages.map((pkg) => (
        <div className={`col ${pkg.highlight && "highlight"}`} key={pkg.name}>
          <h4>{pkg.name}</h4>
          {pkg.price && <h3>{pkg.price}</h3>}
          {pkg.description ? pkg.description : null}
          {pkg.features.length > 0 && (
            <ul>
              {pkg.features.map((feature) => (
                <li key={createUUID()}>{feature}</li>
              ))}
            </ul>
          )}
          {pkg.link && (
            <a href={pkg.link} className="btn">
              {pkg.linkText}
            </a>
          )}
        </div>
      ))}
    </article>
  );
};
